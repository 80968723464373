.coin-body {
  display: flex;
  min-height:100vh; 
  flex-direction:column; 
  justify-content:space-between;
  cursor: url('./assets/yellowRocket.svg'), auto !important;	
}

.coin-body:active {
  cursor: url('./assets/explosion.svg'), auto !important;	
}

tr th {
  border-bottom: solid black 2px !important;
}

.center-align-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-table-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 850px;
  width: 100%;
}

.home-table-inner .tab-title {
  font-size: 13px !important;
}

.home-table {
  width:100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 850px;
}

td:hover {
  cursor: pointer;
}

.home-table-cont {
  width: 100%;
  padding: 0;
}

.table-coinGecko {
  padding-left: 10%;
}

.coinGecko-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1050px) { 
  .home-table-cont {
    padding-top: 0px;
  }
 }

 @media (max-width: 650px) { 
  .home-table-inner .tab-title {
    font-size: 10px !important;
  }
  .home-table-inner button {
    padding: 6px 8px !important;
  }
}

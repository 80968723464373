.table-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.chart-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.half-page-cont {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.hr-cont {
  margin: 50px 150px 50px 150px;
}

.chart-cont button {
  font-size: 14px !important;
}

@media (max-width: 1000px) { 
  .table-page {
    flex-direction: column;
    margin-top: 0;
  }
  .hr-cont {
    margin: 40px 150px 20px 150px;
  }
  .half-page-cont {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 600px) { 
  .chart-cont button {
    font-size: 12px !important;
    padding: 8px !important;
  }
}

@media (max-width: 380px) { 
  .chart-cont button {
    padding: 6px !important;
    font-size: 11px !important;
  }
}
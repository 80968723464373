.port-tab-nav-cont {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;
  width: 100%;
  font-size: 14px;
}

#port-tabs {
  width: 100%;
  height: 100%;
}

.port-stat-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--primary-light);
}

.stat-box-col-1 {
  width: 50%;
  padding: 10px;
  overflow: auto;
  border-right: 6px solid whitesmoke;
}

.stat-box-col-2 {
  width: 50%;
  padding: 10px;
  overflow: auto;
}

@media (max-width: 800px) { 
  .port-tab-nav-cont {
    font-size: 13px;
  }
}

@media (max-width: 550px) { 
  .port-tab-nav-cont {
    font-size: 12px;
  }
  .stat-box-col-1 {
    padding: 5px;
  }
  .stat-box-col-2 {
    padding: 5px;
  }
  
}

.marquee-entry-cont {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  align-items: center;
  padding: 6px 0 6px 0;
  border-bottom: whitesmoke solid 2px;
}

.marquee-entry-cont:hover {
  background-color: var(--light-orange);
  cursor: pointer;
}

.marquee-entry-cont .marquee-icon {
  width: 17px;
  height: 17px;
  display: block;
  margin: 0 5px 0 8px;
}

.marquee-entry-cont .coin-text {
  font-size: 13px;
  font-weight: bold;
}

.marquee-entry-cont .percent-text {
  font-size: 13px;
  font-weight: 600;
  margin: 0 5px 0 5px;
  padding-top: 5px;
}

.marquee-entry-cont .red-text{
  color: red;
}

.marquee-entry-cont .green-text{
  color: green;
}

@media (max-width: 650px) { 
  .marquee-entry-cont {
    padding: 5px 0 5px 0;
  }
  .marquee-entry-cont .coin-text {
    font-size: 11px;
  }
  .marquee-entry-cont .percent-text {
    font-size: 11px;
    padding-top: 2px;
  }
  .marquee-entry-cont .marquee-icon {
    width: 15px;
    height: 15px;
  }
}
.table {
  max-width: 500px;
  max-height: 500px;
  overflow: auto;
}

.table-cont {
  width: 100%;
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.table-cont .table tr {
  font-size: 14px !important;
}

.spinner-outer-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-title {
  font-size: 20px;
  border-bottom: 2px solid black;
}

@media (max-width: 1000px) { 
  .table-cont {
    padding-top: 20px;
    justify-content: center;
    align-items: center;
  }
  .table-title {
    text-align: center;
  }
  .table p {
    text-align: center;
  }
 }

 @media (max-width: 650px) { 
  .table-cont .table tr {
    font-size: 13px !important;
  }
  .dateString {
    font-size: 12px;;
  }
  .table-title {
    text-align: center;
    font-size: 20px;
  }
  .table p {
    text-align: center;
  }
  
 }


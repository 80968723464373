.flex-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20% 0 20%;
}

.homeTable-box {
  width: 65%;
}

.trending-box {
  width: 35%;
  padding: 28px 10px 0px 30px;
}

.flex-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.trending-inner {
  width: 100%;
}

.chat-inner {
  width: 100%;
}

.disclaimer-alert {
  margin: 0 !important;
}

#gecko-price-widget {
  pointer-events: none !important;
}

.coin-chat {
  width: 100%;
}

@media (max-width: 2200px) { 
  .flex-cont {
    padding: 10px 15% 0 15%;
  }
}

@media (max-width: 1800px) { 
  .flex-cont {
    padding: 10px 10% 0 10%;
  }
}

@media (max-width: 1650px) { 
  .flex-cont {
    padding: 10px 7% 0 7%;
  }
  .homeTable-box {
    width: 65%;
  }
  .trending-box {
    width: 35%;
    padding: 30px 0 0 40px;
  }
}

@media (max-width: 1650px) { 
  .flex-cont {
    padding: 10px 5% 0 5%;
  }
}

@media (max-width: 1250px) and (min-width: 1000px) { 
  .flex-cont {
    padding: 10px 3% 0 3%;
  }
  .homeTable-box {
    width: 60%;
    padding: 0 20px 0 20px;
  }
  .trending-box {
    width: 40%;
    padding: 30px 20px 0px 20px;
  }
}

 @media (max-width: 1000px) { 
  .flex-cont {
    flex-direction: column;
    padding: 10px 6%;
  }
  .trending-box {
    width:100%;
    padding: 0;
    margin-top:30px;
  }
  .homeTable-box {
    width:100%;
  }
  .flex-item {
    flex-direction: row;
  }
  .trending-inner {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 600px) { 
  .flex-cont {
    padding: 0 3%;
  }
}

@media (max-width: 750px) { 
  .flex-item {
    flex-direction: column;
  }
}
.form {
  display: flex;
  border-top: 2px solid #DCDCDC;
}

.input {
  border: none;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
  font-size: 14px;
}

input:focus, textarea:focus, select:focus{
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: rgb(255, 124, 63);
  display: inline-block;
  border: none;
  width: 20%;
  border-bottom-right-radius: 6px;
}

.sendButton:hover {
  background: #FF6347;
}
.top-posts-cont {
  margin-top: 40px;
}

.top-posts-cont .post-content-title {
  font-size: 14px
}

.post-row-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  border-radius: 5px;
  padding: 10px;
}

.post-row-cont:hover {
  background-color: hsl(41, 100%, 80%) ;
  cursor: pointer;
}

.post-col-img img {
  margin-left: 10px;
}

.post-content-title {
  font-weight: bold;
}

.post-col-content {
  display: flex;
  flex-direction: column;
}

.post-col-content .timestamp {
  font-size: 12px;
  color: grey;
}

.select-sub-dropdown {
  border: none !important;
  padding: 5px 30px 5px 5px !important;
  cursor: pointer;
  color: black !important;
}

.sub-select-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid black 2.5px !important;
  margin-bottom: 15px;
}

@media (max-width: 530px) { 
  .top-posts-cont h4 {
    font-size: 1.2rem;
  }
  .top-posts-cont .post-content-title {
    font-size: 13px
  }
  .post-col-img img {
    margin-left: 10px;
  }
  .select-sub-dropdown {
    font-size: 14px !important;
    padding-top: 0px !important
  }
}

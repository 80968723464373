.coin-name {
  font-weight: 500;
}

.coin-icon {
  width:20px;
  height: 20px;
  margin: 0 10px 0 0;
}

.home-table th {
  font-size: 14px;
}

.mini-chart-td {
  padding: 5px !important;
}

.home-table .home-chart-td {
  font-size: 13px !important;
}

.index-summary-cont .total-sum {
  font-size: 13px;
  color: #708090;
}

.index-summary-cont .total-sum strong {
  margin-right: 5px;
}

@media (max-width: 550px) { 
  .home-table .home-chart-td {
    font-size: 12px !important;
    padding: 7px 4px !important;
  }
  .home-table .mini-chart-td {
    padding: 3px !important;
  }
  .home-table th {
    font-size: 12px;
  }
  .coin-icon {
    width: 15px;
    height: 15px;
    margin: 0 5px 0 0;
  }
}

@media (max-width: 650px) { 
  .home-table td {
    font-size: 14px;
  }
  .index-summary-cont .total-sum {
    font-size: 12px;
  }
}

@media (max-width: 400px) { 
  .home-table .home-chart-td {
    font-size: 11px !important;
    padding: 8px 4px !important;
  }
  .home-table .mini-chart-td {
    padding: 3px !important;
  }
  .home-table th {
    font-size: 12px;
  }
  .coin-icon {
    width: 12px;
    height: 12px;
  }
}

.publicAPI-outer-cont {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.public-api-info-bar {
  margin-bottom: 20px;
}

.publicAPI-inner-row {
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.publicAPI-inner-col-left {
  width: 40%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.publicAPI-inner-col-right {
  width: 60%;
  background-color: var(--primary-dark);
  color: white;
  padding: 20px;
  border-radius: 7px;
}

@media (max-width: 1500px) { 
  .publicAPI-inner-row {
    width: 90%;
  }
}

@media (max-width: 1000px) { 
  .publicAPI-inner-row {
    flex-direction: column;
    width: 80%;
  }

  .publicAPI-inner-col-left {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .publicAPI-inner-col-right {
    width: 100%;
  }
}

 @media (max-width: 650px) { 
  .publicAPI-inner-row {
    flex-direction: column;
    width: 90%;
  }
  .publicAPI-inner-col-left {
    padding: 0 5px 15px 5px;
  }
  .publicAPI-inner-col-right {
    padding: 12px;
  }
 }

@media (max-width: 550px) { 
  .publicAPI-inner-row {
    width: 95%;
  }
}




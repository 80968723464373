.example-outer-cont {
  font-size: 15px;
}

.language-title {
  display: block;
  margin-bottom: 5px;
  color: violet
}

.green-text {
  color: greenyellow;
}

.red-text {
  color: orange;
}

.white-text {
  color: white;
}

.margin-right {
  margin-right: 5px;
}

.text-margins {
  margin: 0 5px 0 0px;
}

.green-code {
  color: greenyellow;
  font-family: monospace;
}

@media (max-width: 650px) { 
  .example-outer-cont {
    font-size: 12px;
  }
}
.CAPM {
  width: 100%;
  padding-top: 10px;
}

.CAPM-outer-cont {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 25px;
}

.CAPM-inner-cont {
  display: flex;
  width: 30%;
  flex-direction: column;
}

.CAPM-inner-cont div {
  font-size: 14px;
}

.CAPM-inner-cont p {
  font-size: 14px;
}

.CAPM-info-cont {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
}

.CAPM-info-beta {
  width: 60%;
  font-size: 13px;
  background-color: whitesmoke;
  padding: 20px;
  border-radius: 7px;
  margin-top: 20px;
}

.CAPM-info-beta i {
  font-size: 15px;
  padding: 4px 0;
}

.CAPM-date {
  font-size: 12px;
  text-align: center;
  margin-top: 15px;
}

.CAPM-nested-li {
  padding-left: 20px;
  list-style: square inside;
  font-size: 12px;;
}

@media (max-width: 1200px) { 
  .CAPM-info-beta {
    width: 70%;
  }
}

@media (max-width: 1000px) { 
  .CAPM-inner-cont p {
    font-size: 13px;
  }
  .CAPM-inner-cont {
    width: 40%;
  }
  .CAPM-inner-cont div {
    font-size: 13px;
  }
  .CAPM-info-beta {
    width: 80%;
  }
}

@media (max-width: 800px) { 
  .CAPM-outer-cont {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }
  .CAPM-inner-cont {
    width: 70%;
  }
  .CAPM-info-beta {
    width: 90%;
    font-size: 12px;
  }
}

@media (max-width: 500px) { 
  .CAPM-outer-cont {
    padding-top: 0px;
  }
  .CAPM-inner-cont {
    width: 80%;
  }
  .CAPM-info-beta {
    width: 95%;
  }
  .CAPM-info-beta i {
    font-size: 13px;
  }  
  .CAPM-inner-cont div {
    font-size: 12px;
  }
  .CAPM-inner-cont p {
    font-size: 11px;
  }
  .CAPM-date {
    font-size: 11px;
  }
}

@media (max-width: 400px) { 
  .CAPM-inner-cont {
    width: 90%;
  }
}
.under-construction {
  padding: 15% 0px 10% 0;
  text-align: center;
}

.under-construction span {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.nav-tab-btn {
  font-size: 14px !important;
}

@media (max-width: 600px) {
  .nav-tab-btn {
    font-size: 12px !important;
  }  
}

#chat-popup-header {
  cursor: pointer;
  position: absolute;
  right: 0;
}

#chat-popup-cont {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  z-index: 100;
}

@media (max-width: 800px) {
  #chat-popup-cont {
    width: 80%;
  }
}

@media (max-width: 550px) {
  #chat-popup-cont {
    width: 90%;
  }
}
.coinGecko-cont {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: flex-start;
}

.coinGecko-cont img {
  width: 15px;
  margin-left: 7px;
}

.coinGecko-cont a {
  text-decoration: none;
  margin-left: 7px;
  color: #A9A9A9;
}

@media (max-width: 600px) { 
  .coinGecko-cont img {
    width: 15px !important;
    height: 15px !important;
    margin-left: 5px !important;
    margin-top: 5px !important;
  }
  .coinGecko-cont {
    font-size: 10px;
  }
 }
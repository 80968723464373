.csd-entry-cont {
  background-color: black;
  border: solid 2px black;
  color: white;
  padding: 10px 5px 11px 5px;
}

.csd-entry-cont:hover {
  cursor: not-allowed;
  background-color: black;
}

@media (max-width: 650px) { 
  .csd-entry-cont {
    padding: 9px 7px 9px 7px;
  }
}
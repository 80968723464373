.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 15px;
  color: white;
  display: inline-block;
  max-width: 75%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 1px 3%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.1px;
  font-size: 13px;
}

.sentTextAI {
  display: flex;
  align-items: center;
  color: rgb(61, 61, 208) !important;
  letter-spacing: 0.1px;
  font-size: 13px;
  font-weight: 900;
}

.pl-10 {
  padding-left: 5px;
}

.pr-10 {
  padding-right: 5px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}


.background-orange {
  background: #FFA07A;
}

.backgroundLight {
  background: #F3F3F3;
}

.backgroundBlue {
  background: #ddeeff;
}
.chat-page-outer-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 60px 25% 5px 25%;
}

.chat-page-inner-cont .messages-cont {
  height: 50vh;
  overflow: auto;
  flex: auto;
  width: 100% !important;
}

.chat-page-inner-cont {
  width: 100%
}

@media (max-width: 1200px) { 

  .chat-page-outer-cont {
    padding: 60px 15% 5px 15%;
  }
}

@media (max-width: 800px) { 
  .chat-page-inner-cont .messages-cont {
    height: 70vh;
    overflow: auto;
    flex: auto;
  }
  .chat-page-outer-cont {
    padding: 20px 5% 5px 5%;
  }
}

@media (max-width: 550px) { 
  .chat-page-outer-cont {
    padding: 20px 2% 5px 2%;
  }
  
}

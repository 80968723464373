
.chat-outer-cont {
  background-color: white;
  border-radius: 9px;
  border: solid #fcba03;
}

@media (min-width: 320px) and (max-width: 1200px) {
  .textContainer {
    display: none;
  }
}


.search-bar{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.form-horizontal {
  margin-top: 0;
  width: 500px;
  padding: 0 20px 20px 20px;
}

.input-or {
  text-align: center;
}

.input-text-box {
  margin-top: 10px;
}

@media (max-width: 1000px) { 
  .form-horizontal {
    margin-top: 0px;
    width: 70%;
    padding: 20px;
  }
  .search-bar{
    padding-right:0;
    justify-content: center;
  }
 }

 @media (max-width: 650px) { 
  .form-horizontal {
    width: 90%;
  }
 }
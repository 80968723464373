.footer-outer-cont {
  margin-top: 100px;
  width: 100%;
}

.footer-cont {
  background-color: var(--primary-dark);
  color: white;
  width: 100%;
  padding: 5% 10% 5% 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.trade-mark-cont {
  width:30%;
}

.aux-links-cont {
  width:70%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.link-cont {
  margin-left: 50px;
}

.link-cont a {
  color: #A9A9A9;
  text-decoration: none;
  display: block;
}

.link-cont a:hover {
  color: #bd971b;
}

.disclaimer-cont {
  padding: 20px 20px 4px 20px;
}

.title-logo {
  display: flex;
  align-items: center;
}

.title-logo img {
  width: 30px;
  height: 30px;
  margin : 0 0 3px 10px;
}

/* ##### fonts ##### */

.link-cont h5 {
  margin-top: 15px;
}

.disclaimer-p {
  font-size: 11px;
}

.title-logo h2 {
  display: inline-block;
  font-size: 30px;
}

#name-sig {
  color: #A9A9A9;
  font-size: 12px;
}

.footer-cont h5 {
  font-size: 18px;
}

.footer-cont p {
  font-size: 15px;
}

.footer-outer-cont {
  font-size: 14px;
}


@media (max-width: 1300px) { 
  .footer-cont {
    padding: 10% 7% 10% 7%;
  }
  .link-cont {
    margin-left: 30px;
  }
  .footer-outer-cont {
    margin-top: 50px;
  }
 }

 @media (max-width: 900px) { 
  .trade-mark-cont {
    width:70%;
  }
  .aux-links-cont {
    flex-direction: column;
    width:30%;
  }
  .disclaimer-p {
    font-size: 9px;
  }
  .disclaimer-cont {
    padding: 10px 10px 0 10px;
  }
 }

 @media (max-width: 700px) { 
  .footer-cont {
    flex-direction: column;
    padding: 60px 30px 60px 30px;
  }
  .trade-mark-cont {
    width:100%;
  }
  .aux-links-cont {
    width:100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  .link-cont {
    margin-left: 0px;
  }
  .footer-outer-cont {
    margin-top: 50px;
  }
 }

 @media (max-width: 600px) { 
  .footer-outer-cont {
    font-size: 12px;
  }
  .footer-cont p {
    font-size: 13px;
  }
  .footer-cont h5 {
    font-size: 15px;
  }
  .footer-cont h2 {
    font-size: 23px;
  }
  .footer-cont img {
    width: 25px;
    height: 25px;
    margin : 0 0 5px 7px;
  }
  #name-sig {
    font-size: 11px;
  }
 }
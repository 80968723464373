.global-index-cont {
  overflow-y: auto;
  max-height: 1300px;
}
.global-index-cont .datetime-td {
  font-weight: 500;
}

.global-index-cont td {
  font-size: 13px !important;
}

.global-index-cont th {
  font-size: 13px;
}

@media (max-width: 800px) { 
  .global-index-cont td {
    font-size: 12px !important;
  }
}

@media (max-width: 650px) { 
  .global-index-cont th {
    font-size: 12px;
  }
}

@media (max-width: 500px) { 
  .global-index-cont th {
    font-size: 11px;
  }
  .global-index-cont td {
    font-size: 11px !important;
  }
}



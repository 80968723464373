.CAPMM-inner-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.CAPMM-table-cont {
  margin-top: 20px;
  width: auto;
  max-height: 500px;
  overflow-y: auto;
}

.CAPMM-graph-cont {
  margin: 20px 20px;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 7px;
}

.CAPMM-table-cont th {
  padding: 7px;
  font-size: 13px;
}

.CAPMM-table-cont td {
  padding: 7px;
  font-size: 12px;
}

.loading-cont {
  margin: 60px 0;
}

@media (max-width: 600px) {
  .CAPMM-inner-cont h4 {
    font-size: 16px;
  }
  .loading-cont {
    margin: 30px 0;
  }
}

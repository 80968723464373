.trending-table-cont {
  width: 100%;
}

.trending-table thead{
  border-bottom: 2px solid black !important;
}

.trending-table {
  margin: 0 auto 0 auto;
  width: 100%;
}

.trending-table tr {
  font-size: 14px;
}

@media (max-width: 1000px) { 
  .trending-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .trending-table-cont h4 {
    text-align: center;
  }
}

@media (max-width: 530px) { 
  .trending-table-cont h4 {
    text-align: left;
    font-size: 1.2rem;
  }
  .trending-table tr {
    font-size: 14px;
  }
  .trending-table th {
    font-size: 12px;
  }
}


.info-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fcba03;
  border-radius: 4px 4px 0 0;
  height: 60px;
  width: 100%;
}

.left-inner-cont {
  flex: 0.5;
  display: flex;
  align-items: center;
  margin-left: 5%;
  color: white;
  margin-top: 10px;
}

.left-inner-cont h3 a {
  font-size: 20px;
  color: white;
  text-decoration: none;
  font-weight: 700;
}

.left-inner-cont h3 a:hover {
  color: orange;
}

.right-inner-cont {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 5%;
}

.right-inner-cont a {
  color: white;
  text-decoration: none;
}

.right-inner-cont button {
  background: none;
  border: none;
  color: white;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.right-inner-cont button:hover {
  color: orange;
}

.csd-icon {
  width: 30px;
  margin: auto 0 auto 15px;
  display: block;
}

.join-cont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.alert-box {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top:40%;
  z-index: 100;
}

@media (max-width: 600px) { 
  .alert-box {
    width: 80%;
  }
 }
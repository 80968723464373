.portfolioOpt-outer-cont {
  display: flex;
  flex-direction: row;
  padding: 3% 20% 0 20%;
  align-items: center;
}

.portfolioOpt-outer-cont .graph-cont{
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: whitesmoke;
  border-radius: 10px;
}

.portfolioOpt-outer-cont .loading-cont {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 10px;
}

.portfolioOpt-outer-cont .search-cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding: 30px;
}

.graph-cont img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1600px) { 
  .portfolioOpt-outer-cont {
    padding: 40px 15% 0 15%;
  }
}

@media (max-width: 1300px) { 
  .portfolioOpt-outer-cont {
    padding: 40px 5% 0 5%;
  }
}

 @media (max-width: 1100px) { 
  .portfolioOpt-outer-cont {
    flex-direction: column-reverse;
    padding: 20px 20% 0 20%;
  }
  .portfolioOpt-outer-cont .graph-cont {
    width: 100%;
  }
  .portfolioOpt-outer-cont .loading-cont{
    width: 100%;
    height: 200px;
  }
  .portfolioOpt-outer-cont .search-cont{
    width: 100%;
  }
}

 @media (max-width: 900px) { 
  .portfolioOpt-outer-cont {
    flex-direction: column-reverse;
    padding: 20px 15% 0 15%;
  }
 }

 @media (max-width: 750px) { 
  .portfolioOpt-outer-cont {
    flex-direction: column-reverse;
    padding: 20px 5% 0 5%;
  }
 }

 @media (max-width: 600px) { 
  .portfolioOpt-outer-cont {
    flex-direction: column-reverse;
    padding: 10px 3% 0 3%;
  }
  .portfolioOpt-outer-cont .graph-cont {
    padding: 10px;
  }
  .portfolioOpt-outer-cont .search-cont {
    margin-top: 20px;
    padding: 10px;
  }
  .graph-cont img {
    padding: 0 ;
  }
 }